export default {
  apiKey: 'AIzaSyDfc5Irwmac20Fe1GU5L9KabKp2ShgO8nE',
  authDomain: 'flowledgerdev.firebaseapp.com',
  databaseURL: 'https://flowledgerdev.firebaseio.com',
  fileApiUrl: 'https://europe-west3-flowledgerdev.cloudfunctions.net/get-file-api',
  projectId: 'flowledgerdev',
  messagingSenderId: '1068217764285',
  storageBucket: 'flowledgerdev.appspot.com',
  xerUploadBucketName: "xerfiles-bucket",
  appId: '1:1068217764285:web:79e23302f12f73fe',
  messagingProjectsPublicKey:
    'BECpdOsLnX8W-GyI0cG6Ns07rX_mfFOKjB0RbUKfllutu8BY2l_kKDuEh_TmQFAugh0QUy3j6z6j5aEjQLYZUF0',
  vapidKey:
    'BFSyejrX4R3zTnNjwhJpq0K_wb1eDRU6zj98VEzf88eAjMK58EHTafAl4pcV47y5JJDSVmh5Smup4g_wj83q7no',
};
